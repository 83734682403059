import { classNames } from "@aletheia/givingwhatwecan/design/components/utils/classNames";
var marginBottomMap = {
    "0": "!mb-0",
    "1": "!mb-1",
    "2": "!mb-2",
    "3": "!mb-3",
    "4": "!mb-4",
    "5": "!mb-5",
    "6": "!mb-6",
    "7": "!mb-7",
    "8": "!mb-8",
    "10": "!mb-10",
    "12": "!mb-12",
    "14": "!mb-14",
    "16": "!mb-16",
    "20": "!mb-20",
    "24": "!mb-24"
};
export var marginTopMap = {
    "0": "!mt-0",
    "1": "!mt-1",
    "2": "!mt-2",
    "3": "!mt-3",
    "4": "!mt-4",
    "5": "!mt-5",
    "6": "!mt-6",
    "7": "!mt-7",
    "8": "!mt-8",
    "10": "!mt-10",
    "12": "!mt-12",
    "14": "!mt-14",
    "16": "!mt-16",
    "20": "!mt-20",
    "24": "!mt-24"
};
export var getCustomClasses = function(props) {
    var ref, ref1;
    var customMargin = props === null || props === void 0 ? void 0 : (ref = props.value) === null || ref === void 0 ? void 0 : (ref1 = ref.markDefs) === null || ref1 === void 0 ? void 0 : ref1.find(function(markDef) {
        return markDef._type === "customMargin";
    });
    return classNames((customMargin === null || customMargin === void 0 ? void 0 : customMargin.marginTop) && marginTopMap[customMargin.marginTop], (customMargin === null || customMargin === void 0 ? void 0 : customMargin.marginBottom) && marginBottomMap[customMargin.marginBottom]);
};
