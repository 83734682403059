import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { sumBy } from "lodash";
export var MAX_ALLOCATION_ORGS = 10;
/** Check whether the Allocation is currently maxed out */ export var getAllocationAtMax = function(Allocation) {
    return Allocation.length >= MAX_ALLOCATION_ORGS;
};
/**
 * Recalculate an Allocation by setting each item's percentage to be as equal as
 * possible
 */ export var equalizeAllocation = function(Allocation) {
    // avoid division by zero errors
    if (!Allocation.length) return Allocation;
    var basePercentage = Math.floor(100 / Allocation.length);
    var remainder = 100 % basePercentage;
    return Allocation.map(function(param, i) {
        var organization = param.organization, isExtra = param.isExtra;
        var newItem = {
            // put any remainder percentage on the first item
            percentage: i === 0 ? basePercentage + remainder : basePercentage,
            organization: organization
        };
        if (isExtra) newItem.isExtra = true;
        return newItem;
    });
};
/**
 * Recalculate an allocation while keeping the ratios between percentages
 * as close to the original as possible (e.g. 10, 30 should become 25, 75)
 */ export var normalizeAllocation = function(Allocation) {
    var opts = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
        round: true
    };
    if (!Allocation.length) return Allocation;
    var totalPercentage = sumBy(Allocation, "percentage");
    var newAllocation = Allocation.map(function(param) {
        var organization = param.organization, percentage = param.percentage, isExtra = param.isExtra;
        var newItem = {
            organization: organization,
            percentage: opts.round ? Math.round(percentage * 100 / totalPercentage) : percentage * 100 / totalPercentage
        };
        if (isExtra) newItem.isExtra = true;
        return newItem;
    });
    var remainder = 100 - sumBy(newAllocation, "percentage");
    if (remainder === 0) return newAllocation;
    if (!opts.round) {
        newAllocation[0].percentage += remainder;
        return newAllocation;
    }
    var sortedElements = _to_consumable_array(newAllocation).sort(function(a, b) {
        return b.percentage - a.percentage;
    });
    // Distribute the remainder evenly among items, from the largest to the smallest
    for(var i = 0; i < Math.abs(remainder); i++)sortedElements[i % sortedElements.length].percentage += Math.sign(remainder);
    return newAllocation;
};
/**
 * Add an Organization to an Allocation
 *
 * @param Organization The Organization to add to the allocation
 * @param Allocation The Allocation to add the Organization to
 * @param recalculate A function that takes in an Allocation, and distributes
 *     the percentages according to some algorithm, such that the percentages
 *     sum to 100%
 */ export function addOrganizationToAllocation(Organization, Allocation) {
    var recalculate = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : equalizeAllocation;
    // if the organization is already on the allocation, just return the allocation
    if (Allocation.some(function(Item) {
        return Item.organization === Organization.slug;
    })) return Allocation;
    return recalculate(Allocation.concat({
        organization: Organization.slug,
        percentage: 0
    }));
}
/** Remove an Organization from an Allocation */ export function removeOrganizationFromAllocation(Organization, Allocation) {
    var recalculate = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : normalizeAllocation;
    return recalculate(Allocation.filter(function(Item) {
        return Item.organization !== Organization.slug;
    }));
}
