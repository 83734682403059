import { Divider, Box, BoxProps } from '@material-ui/core'
import React from 'react'

type HRProps = BoxProps & { verticalPadding?: number }

const HR: React.FC<HRProps> = ({ verticalPadding = 3, ...boxProps }) => {
  return (
    <Box
      paddingTop={verticalPadding}
      paddingBottom={verticalPadding}
      {...boxProps}
    >
      <Divider />
    </Box>
  )
}

export default HR
